// Matches 1, 0.3, 1.1.1, 4.1.5.7, etc.
const versionRegex = /^[0-9]+(\.[0-9]+)*$/;

/**
 * versionGreaterThan - Returns true if version1 is greater than version2
 *
 * @param {String} version1 The version to compare from
 * @param {String} version2 The version to compare to
 *
 */

module.exports = function versionGreaterThan(version1, version2) {
  if (!versionRegex.test(version1) || !versionRegex.test(version2)) {
    throw new Error('could not recognize version');
  }

  const v1 = String(version1).split('.').map(Number);
  const v2 = String(version2).split('.').map(Number);

  // There is a way to nicely compare arrays of different lengths, but in our case, if there is a
  // mismatch, it indicates something went wrong. So we throw instead.

  if (v1.length !== v2.length) {
    throw new Error('versions must be same length');
  }

  const len = v1.length;

  for (let i = 0; i < len; i += 1) {
    // Continue until we find a pair that doesn't match.
    // The first mismatched pair determines the overall result.
    if (v1[i] !== v2[i]) {
      return v1[i] > v2[i];
    }
  }

  // If all the pieces are equal, then the versions should be equal.
  if (version1 !== version2) {
    throw new Error('versions should be equal');
  }

  // And since they're equal, version1 is not greater than version2.
  return false;
};
