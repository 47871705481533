

// When setting the background image uri of a publisher or subscriber, we allow the use of a raw
// data blob of a png, which requires the prefix 'data:image/png;base64,'. This function
// encapsulates that transformation.
module.exports = function fixBackgroundImageURI(bgImgURI) {
  if (
    bgImgURI.substr(0, 5) !== 'http:' &&
    bgImgURI.substr(0, 6) !== 'https:' &&
    bgImgURI.substr(0, 22) !== 'data:image/png;base64,'
  ) {
    return `data:image/png;base64,${bgImgURI}`;
  }

  return bgImgURI;
};
