// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-underscore-dangle, no-param-reassign, no-void */
/* eslint-disable no-restricted-syntax, no-prototype-builtins, no-shadow, one-var, vars-on-top */
/* eslint-disable no-var */

const env = require('../helpers/env');
const isObject = require('lodash/isObject');

let getErrorLocation;

// Properties that we'll acknowledge from the JS Error object
const safeErrorProps = [
  'description',
  'fileName',
  'lineNumber',
  'message',
  'name',
  'number',
  'stack',
];

// OTHelpers.Error
//
// A construct to contain error information that also helps with extracting error
// context, such as stack trace.
//
// @constructor
// @memberof OTHelpers
// @method Error
//
// @param {String} message
//      Optional. The error message
//
// @param {Object} props
//      Optional. A dictionary of properties containing extra Error info.
//
//
// @example Create a simple error with juts a custom message
//   var error = new OTHelpers.Error('Something Broke!');
//   error.message === 'Something Broke!';
//
// @example Create an Error with a message and a name
//   var error = new OTHelpers.Error('Something Broke!', 'FooError');
//   error.message === 'Something Broke!';
//   error.name === 'FooError';
//
// @example Create an Error with a message, name, and custom properties
//   var error = new OTHelpers.Error('Something Broke!', 'FooError', {
//     foo: 'bar',
//     listOfImportantThings: [1,2,3,4]
//   });
//   error.message === 'Something Broke!';
//   error.name === 'FooError';
//   error.foo === 'bar';
//   error.listOfImportantThings == [1,2,3,4];
//
// @example Create an Error from a Javascript Error
//   var error = new OTHelpers.Error(domSyntaxError);
//   error.message === domSyntaxError.message;
//   error.name === domSyntaxError.name === 'SyntaxError';
//   // ...continues for each properties of domSyntaxError
//
// @references
// * https://code.google.com/p/v8/wiki/JavaScriptStackTraceApi
// * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/Stack
// * http://www.w3.org/TR/dom/#interface-domerror
//
//
// @todo
// * update usage in OTMedia
// * replace error handling in OT.js
// * normalise stack behaviour under Chrome/Node/Safari with other browsers
// * unit test for stack parsing
//
const Error_ = function (message, name, props) {
  switch (arguments.length) {
    case 1:
      if (isObject(message)) {
        props = message;
        name = void 0;
        message = void 0;
      }
      // Otherwise it's the message
      break;

    case 2:
      if (isObject(name)) {
        props = name;
        name = void 0;
      }
      // Otherwise name is actually the name
      break;

    default:
      // FIXME: Should something go here?
  }

  if (props instanceof Error) {
    // Special handling of this due to Chrome weirdness. It seems that
    // properties of the Error object, and it's children, are not
    // enumerable in Chrome?
    for (let i = 0, num = safeErrorProps.length; i < num; ++i) {
      this[safeErrorProps[i]] = props[safeErrorProps[i]];
    }
  } else if (isObject(props)) {
    // Use an custom properties that are provided
    for (const key in props) {
      if (props.hasOwnProperty(key)) {
        this[key] = props[key];
      }
    }
  }

  // If any of the fundamental properties are missing then try and
  // extract them.
  if (!(this.fileName && this.lineNumber && this.columnNumber && this.stack)) {
    const err = getErrorLocation();

    if (!this.fileName && err.fileName) {
      this.fileName = err.fileName;
    }

    if (!this.lineNumber && err.lineNumber) {
      this.lineNumber = err.lineNumber;
    }

    if (!this.columnNumber && err.columnNumber) {
      this.columnNumber = err.columnNumber;
    }

    if (!this.stack && err.stack) {
      this.stack = err.stack;
    }
  }

  if (!this.message && message) {
    this.message = message;
  }

  if ((!this.name || this.name === 'Error') && name) {
    this.name = name;
  }
};

module.exports = Error_;

Error_.prototype = Object.create(Error.prototype);
Error_.prototype.toString = function () {
  let locationDetails = '';
  if (this.fileName) {
    locationDetails += ` ${this.fileName}`;
  }

  if (this.lineNumber) {
    locationDetails += ` ${this.lineNumber}`;

    if (this.columnNumber) {
      locationDetails += `:${this.columnNumber}`;
    }
  }

  return `<${this.name ? `${this.name} ` : ''}${this.message}${locationDetails}>`;
};

Error_.prototype.valueOf = Error_.prototype.toString;

// Normalise err.stack so that it is the same format as the other browsers
// We skip the first two frames so that we don't capture getErrorLocation() and
// the callee.
//
// Used by Environments that support the StackTrace API. (Chrome, Node, Opera)
//
const prepareStackTrace = function prepareStackTrace(_, stack) {
  return stack.slice(2).map((frame) => {
    const _f = {
      fileName: frame.getFileName(),
      linenumber: frame.getLineNumber(),
      columnNumber: frame.getColumnNumber(),
    };

    if (frame.getFunctionName()) {
      _f.functionName = frame.getFunctionName();
    }

    if (frame.getMethodName()) {
      _f.methodName = frame.getMethodName();
    }

    if (frame.getThis()) {
      _f.self = frame.getThis();
    }

    return _f;
  });
};


// Black magic to retrieve error location info for various environments
getErrorLocation = function getErrorLocation() {
  const info = {};
  let callstack,
    errLocation,
    err;

  switch (env.name) {
    case 'Firefox':
    case 'Safari':
      try {
        global.call.js.is.explody();
      } catch (e) {
        err = e;
      }

      callstack = (err.stack || '').split('\n');

      // Remove call to getErrorLocation() and the callee
      callstack.shift();
      callstack.shift();

      info.stack = callstack;

      errLocation = /@(.+?):([0-9]+)(:([0-9]+))?$/.exec(callstack[0]);
      if (errLocation) {
        info.fileName = errLocation[1];
        info.lineNumber = parseInt(errLocation[2], 10);
        if (errLocation.length > 3) {
          info.columnNumber = parseInt(errLocation[4], 10);
        }
      }
      break;

    case 'Chrome':
    case 'Node':
    case 'Opera':
      var currentPST = Error.prepareStackTrace;
      Error.prepareStackTrace = prepareStackTrace;
      err = new Error();
      info.stack = err.stack;
      Error.prepareStackTrace = currentPST;

      var topFrame = info.stack[0];
      info.lineNumber = topFrame.lineNumber;
      info.columnNumber = topFrame.columnNumber;
      info.fileName = topFrame.fileName;

      if (topFrame.functionName) {
        info.functionName = topFrame.functionName;
      }

      if (topFrame.methodName) {
        info.methodName = topFrame.methodName;
      }

      if (topFrame.self) {
        info.self = topFrame.self;
      }

      break;

    default:
      err = new Error();

      if (err.stack) {
        info.stack = err.stack.split('\n');
      }

      break;
  }

  if (err.message) {
    info.message = err.message;
  }

  return info;
};
