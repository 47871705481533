const logging = require('../helpers/log')('videoContentHint');

const videoContentHints = ['', 'text', 'motion', 'detail'];

const isValidVideoContentHint = hint => videoContentHints.includes(hint);


const getVideoTrackForStream = mediaStream => mediaStream?.getVideoTracks()?.[0];

/**
 * Sets contentHint for videoTrack of MediaStream
 * @param {MediaStream} mediaStream
 * @param {String} contentHint, one of "", "motion", "detail" or "text"
 */
const setVideoContentHint = (mediaStream, contentHint) => {
  if (!isValidVideoContentHint(contentHint)) {
    logging.warn('Invalid content hint. Valid content hints are "text", "detail", "motion", or ""');
    return;
  }
  const videoTrack = getVideoTrackForStream(mediaStream);
  if (!videoTrack) {
    logging.warn('Tried to set contentHint but no video track is present');
    return;
  }
  if (videoTrack.contentHint === undefined) {
    logging.warn('contentHint not supported by this browser');
    return;
  }
  videoTrack.contentHint = contentHint;
};

/**
 * Returns contentHint for videoTrack of MediaStream
 * @param {MediaStream} mediaStream
 * @returns {String} contentHint, one of "", "motion", "detail" or "text"

 */
const getVideoContentHint = (mediaStream) => {
  const videoTrack = getVideoTrackForStream(mediaStream);
  return videoTrack?.contentHint || '';
};

module.exports = {
  isValidVideoContentHint,
  setVideoContentHint,
  getVideoContentHint,
};
