// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable global-require */


module.exports = function interpretPeerConnectionErrorFactory(deps = {}) {
  const Errors = deps.Errors || require('./Errors.js');
  const ExceptionCodes = deps.ExceptionCodes || require('./exception_codes.js');
  const otError = deps.otError || require('../helpers/otError.js')();

  const errorPrefixMap = {
    ICEWorkflow: Errors.ICE_WORKFLOW_FAILED,
    NewPeerConnection: Errors.CREATE_PEER_CONNECTION_FAILED,
    SetRemoteDescription: Errors.SET_REMOTE_DESCRIPTION_FAILED,
    Subscribe: {
      404: Errors.STREAM_NOT_FOUND,
      [ExceptionCodes.STREAM_LIMIT_EXCEEDED]: Errors.STREAM_LIMIT_EXCEEDED,
    },
  };

  return function interpretPeerConnectionError(
    httpCode,
    reason,
    prefix,
    remoteConnectionId,
    className
  ) {
    const message = `${(prefix ? `${prefix}: ` : '') + className} PeerConnection with connection ${
      remoteConnectionId} failed: ${reason}`;

    const nameOrCodeMap = errorPrefixMap[prefix] || {};

    const name = typeof nameOrCodeMap === 'string' ? nameOrCodeMap : nameOrCodeMap[httpCode];

    const defaultCode = (
      className === 'Publisher' ?
        ExceptionCodes.UNABLE_TO_PUBLISH :
        ExceptionCodes.UNABLE_TO_SUBSCRIBE
    );

    if (!name) {
      const error = new Error(message);
      error.code = defaultCode;

      return error;
    }

    const code = (() => {
      if (prefix === 'ICEWorkflow') {
        return (
          className === 'Publisher' ?
            ExceptionCodes.PUBLISHER_ICE_WORKFLOW_FAILED :
            ExceptionCodes.SUBSCRIBER_ICE_WORKFLOW_FAILED
        );
      }

      if (name === Errors.STREAM_LIMIT_EXCEEDED) {
        return ExceptionCodes.STREAM_LIMIT_EXCEEDED;
      }

      return defaultCode;
    })();

    return otError(
      name,
      new Error(message),
      code
    );
  };
};
