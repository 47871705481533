// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, prefer-rest-params */

const isFunction = require('lodash/isFunction');

module.exports = function statable(
  self,
  possibleStates,
  initialState,
  stateChanged,
  stateChangedFailed
) {
  let previousState;
  let currentState = initialState;
  self.currentState = initialState;

  const setState = function (state) {
    if (currentState !== state) {
      if (possibleStates.indexOf(state) === -1) {
        if (stateChangedFailed && isFunction(stateChangedFailed)) {
          stateChangedFailed('invalidState', state);
        }
        return;
      }


      self.previousState = currentState;
      previousState = currentState;
      self.currentState = state;
      currentState = state;

      if (stateChanged && isFunction(stateChanged)) {
        stateChanged(state, previousState);
      }
    }
  };

  // Returns a number of states and returns true if the current state
  // is any of them.
  //
  // @example
  // if (this.is('connecting', 'connected')) {
  //   // do some stuff
  // }
  //
  self.is = function (/* state0:String, state1:String, ..., stateN:String */) {
    return Array.prototype.indexOf.call(arguments, currentState) !== -1;
  };

  // Returns a number of states and returns true if the current state
  // is none of them.
  //
  // @example
  // if (this.isNot('connecting', 'connected')) {
  //   // do some stuff
  // }
  //
  self.isNot = function (/* state0:String, state1:String, ..., stateN:String */) {
    return Array.prototype.indexOf.call(arguments, currentState) === -1;
  };

  return setState;
};
