// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable one-var, no-underscore-dangle, no-mixed-operators,  no-param-reassign */


const Widget = require('./behaviour/widget.js');
const OTHelpers = require('../../common-js-helpers/OTHelpers.js');

// Archving Chrome Widget
//
// mode (String)
// Whether to display the archving widget. Possible values are: "on" (the status is displayed
// when archiving and briefly when archving ends) and "off" (the status is not displayed)

// Whether to display the archving widget. Possible values are: "auto" (the name is displayed
// when the status is first displayed and when the user mouses over the display),
// "off" (the name is not displayed), and "on" (the name is displayed).
//
// displays a name
// can be shown/hidden
// can be destroyed
module.exports = function Archiving(options) {
  let _lightBox,
    _light,
    _text,
    _textNode,
    renderStageDelayedAction;
  const self = this;
  let _archiving = options.archiving;
  const _archivingStarted = options.archivingStarted || 'Archiving on';
  const _archivingEnded = options.archivingEnded || 'Archiving off';
  let _initialState = true;

  const renderText = function (text) {
    _textNode.nodeValue = text;
    _lightBox.setAttribute('title', text);
  };

  const renderStage = function () {
    if (renderStageDelayedAction) {
      clearTimeout(renderStageDelayedAction);
      renderStageDelayedAction = null;
    }

    if (_archiving) {
      OTHelpers.addClass(_light, 'OT_active');
    } else {
      OTHelpers.removeClass(_light, 'OT_active');
    }

    OTHelpers.removeClass(self.domElement, `OT_archiving-${!_archiving ? 'on' : 'off'}`);
    OTHelpers.addClass(self.domElement, `OT_archiving-${_archiving ? 'on' : 'off'}`);
    if (options.show && _archiving) {
      renderText(_archivingStarted);
      OTHelpers.addClass(_text, 'OT_mode-on');
      OTHelpers.removeClass(_text, 'OT_mode-auto');
      self.setDisplayMode('on');
      renderStageDelayedAction = setTimeout(() => {
        OTHelpers.addClass(_text, 'OT_mode-auto');
        OTHelpers.removeClass(_text, 'OT_mode-on');
      }, 5000);
    } else if (options.show && !_initialState) {
      OTHelpers.addClass(_text, 'OT_mode-on');
      OTHelpers.removeClass(_text, 'OT_mode-auto');
      self.setDisplayMode('on');
      renderText(_archivingEnded);
      renderStageDelayedAction = setTimeout(() => {
        self.setDisplayMode('off');
      }, 5000);
    } else {
      self.setDisplayMode('off');
    }
  };

  // Mixin common widget behaviour
  Widget(this, {
    mode: _archiving && options.show && 'on' || 'off',
    nodeName: 'h1',
    htmlAttributes: { className: 'OT_archiving OT_edge-bar-item OT_edge-bottom' },
    onCreate() {
      _lightBox = OTHelpers.createElement('div', {
        className: 'OT_archiving-light-box',
      }, '');
      _light = OTHelpers.createElement('div', {
        className: 'OT_archiving-light',
      }, '');
      _lightBox.appendChild(_light);
      _text = OTHelpers.createElement('div', {
        className: 'OT_archiving-status OT_mode-on OT_edge-bar-item OT_edge-bottom',
      }, '');
      _textNode = document.createTextNode('');
      _text.appendChild(_textNode);
      self.domElement.appendChild(_lightBox);
      self.domElement.appendChild(_text);
      renderStage();
    },
  });

  this.setShowArchiveStatus = function (show) {
    options.show = show;
    if (self.domElement) {
      renderStage.call(self);
    }
  };

  this.setArchiving = function (status) {
    _archiving = status;
    _initialState = false;
    if (self.domElement) {
      renderStage.call(self);
    }
  };
};
