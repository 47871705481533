// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, no-shadow */

const isFunction = require('lodash/isFunction');
const uuid = require('uuid');
const logging = require('../helpers/log')('guidStorage');

const guidStorage = {};
module.exports = guidStorage;

let currentGuidStorage;
let currentGuid;

const isInvalidStorage = function isInvalidStorage(storageInterface) {
  return !(
    isFunction(storageInterface.get) &&
    isFunction(storageInterface.set)
  );
};

const getClientGuid = function getClientGuid(completion) {
  if (currentGuid) {
    completion(null, currentGuid);
    return;
  }

  // It's the first time that getClientGuid has been called
  // in this page lifetime. Attempt to load any existing Guid
  // from the storage
  currentGuidStorage.get(completion);
};

/*
* Sets the methods for storing and retrieving client GUIDs persistently
* across sessions. By default, OpenTok.js attempts to use browser cookies to
* store GUIDs.
* <p>
* Pass in an object that has a <code>get()</code> method and
* a <code>set()</code> method.
* <p>
* The <code>get()</code> method must take one parameter: the callback
* method to invoke. The callback method is passed two parameters &mdash;
* the first parameter is an error object or null if the call is successful;
* and the second parameter is the GUID (a string) if successful.
* <p>
* The <code>set()</code> method must include two parameters: the GUID to set
* (a string) and the callback method to invoke. The callback method is
* passed an error object on error, or it is passed no parameter if the call is
* successful.
* <p>
* Here is an example:
* <p>
* <pre>
* var ComplexStorage = function() {
*   this.set = function(guid, completion) {
*     AwesomeBackendService.set(guid, function(response) {
*       completion(response.error || null);
*     });
*   };
*   this.get = function(completion) {
*     AwesomeBackendService.get(function(response, guid) {
*       completion(response.error || null, guid);
*     });
*   };
* };
*
* OT.overrideGuidStorage(new ComplexStorage());
* </pre>
*/
guidStorage.override = (storageInterface) => {
  if (isInvalidStorage(storageInterface)) {
    throw new Error('The storageInterface argument does not seem to be valid, ' +
                                      'it must implement get and set methods');
  }

  if (currentGuidStorage === storageInterface) {
    return;
  }

  currentGuidStorage = storageInterface;

  // If a client Guid has already been assigned to this client then
  // let the new storage know about it so that it's in sync.
  if (currentGuid) {
    currentGuidStorage.set(currentGuid, (error) => {
      if (error) {
        logging.error(`Failed to send initial Guid value (${currentGuid
        }) to the newly assigned Guid Storage. The error was: ${error}`);
        // @todo error
      }
    });
  }
};

guidStorage.get = (completion) => {
  getClientGuid((error, guid) => {
    if (error) {
      completion(error);
      return;
    }

    if (!guid) {
      // Nothing came back, this client is entirely new.
      // generate a new Guid and persist it
      guid = uuid();
      currentGuidStorage.set(guid, (error) => {
        if (error) {
          completion(error);
          return;
        }

        currentGuid = guid;
      });
    } else if (!currentGuid) {
      currentGuid = guid;
    }

    completion(null, currentGuid);
  });
};

// Implement our default storage mechanism, which sets/gets a cookie
// called 'opentok_client_id'
guidStorage.override({
  get(completion) {
    let clientId;

    try {
      clientId = global.localStorage.getItem('opentok_client_id');
    } catch (err) {
      // will get Uncaught DOMException: Failed to read the 'localStorage' property from 'Window':
      // The document is sandboxed and lacks the 'allow-same-origin' flag.
      if (!clientId) {
        clientId = uuid();
      }
    }

    completion(null, clientId);
  },

  set(guid, completion) {
    try {
      global.localStorage.setItem('opentok_client_id', guid);
    } catch (err) {
      // will get Uncaught DOMException: Failed to read the 'localStorage' property from 'Window':
      // The document is sandboxed and lacks the 'allow-same-origin' flag.
    }
    completion(null);
  },
});

// Test only
guidStorage.set = (guid) => { currentGuid = guid; };
