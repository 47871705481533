// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */


module.exports = function PublishingStateFactory(deps = {}) {
  const generateSimpleStateMachine = deps.generateSimpleStateMachine || require('../generate_simple_state_machine.js')();

  // Models a Publisher's publishing State
  //
  // Valid States:
  //    NotPublishing
  //    GetUserMedia
  //    BindingMedia
  //    MediaBound
  //    PublishingToSession
  //    Publishing
  //    Failed
  //    Destroyed
  //
  //
  // Valid Transitions:
  //    NotPublishing ->
  //        GetUserMedia
  //
  //    GetUserMedia ->
  //        BindingMedia
  //      | Failed                     (Failure Reasons -> stream error, constraints,
  //                                   (permission denied
  //      | NotPublishing              (destroy()
  //
  //
  //    BindingMedia ->
  //        MediaBound
  //      | Failed                     (Failure Reasons -> Anything to do with the media
  //                                   (being invalid, the media never plays
  //      | NotPublishing              (destroy()
  //
  //    MediaBound ->
  //        PublishingToSession        (MediaBound could transition to PublishingToSession
  //                                   (if a stand-alone publish is bound to a session
  //      | Failed                     (Failure Reasons -> media issues with a stand-alone publisher
  //      | NotPublishing              (destroy()
  //
  //    PublishingToSession
  //        Publishing
  //      | Failed
  //      | NotPublishing              (destroy()
  //      | MediaBound                 (disconnect()
  //
  //
  //    Publishing ->
  //        NotPublishing              (Unpublish
  //      | Failed                     (Failure Reasons -> loss of network, media error, anything
  //                                   (that causes *all* Peer Connections to fail (less than all
  //                                   (failing is just an error, all is failure)
  //      | NotPublishing              (destroy()
  //
  //    Failed ->
  //       Destroyed
  //
  //    Destroyed ->                   (Terminal state
  //
  //

  const validStates = [
    'NotPublishing', 'GetUserMedia', 'BindingMedia', 'MediaBound',
    'PublishingToSession', 'Publishing', 'Failed',
    'Destroyed',
  ];

  const validTransitions = {
    NotPublishing: ['NotPublishing', 'GetUserMedia', 'Destroyed'],
    GetUserMedia: ['BindingMedia', 'Failed', 'NotPublishing', 'Destroyed'],
    BindingMedia: ['MediaBound', 'Failed', 'NotPublishing', 'Destroyed'],
    MediaBound: ['NotPublishing', 'PublishingToSession', 'Failed', 'Destroyed'],
    PublishingToSession: ['NotPublishing', 'Publishing', 'Failed', 'Destroyed', 'MediaBound'],
    Publishing: ['NotPublishing', 'MediaBound', 'Failed', 'Destroyed'],
    Failed: ['Destroyed'],
    Destroyed: [],
  };

  const initialState = 'NotPublishing';

  const PublishingState = generateSimpleStateMachine(initialState, validStates, validTransitions);

  PublishingState.prototype.isDestroyed = function () {
    return this.current === 'Destroyed';
  };

  PublishingState.prototype.isAttemptingToPublish = function () {
    return [
      'GetUserMedia',
      'BindingMedia',
      'MediaBound',
      'PublishingToSession',
    ].indexOf(this.current) !== -1;
  };

  PublishingState.prototype.isPublishing = function () {
    return this.current === 'Publishing';
  };

  return PublishingState;
};
