// @todo enable the following disabled rules see OPENTOK-31136 for more info
/* eslint-disable no-param-reassign, global-require */


module.exports = function otErrorFactory(deps = {}) {
  const Errors = deps.Errors || require('../ot/Errors.js');
  const OTErrorClass = deps.OTErrorClass || require('../ot/ot_error_class.js');

  const names = Object.keys(Errors).map(shortName => Errors[shortName]);

  return function otError(name, plainError, code) {
    if (names.indexOf(name) === -1) {
      return new Error(`Attempt to use invalid error name (${name}). Original message: ${plainError.message}`);
    }

    if (!(plainError instanceof Error || /^\[object .*Error\]$/.test(Object.prototype.toString.call(plainError)))) {
      return new Error(`Did not pass Error as second argument: ${plainError}`);
    }

    // OTErrorClass is the deprecated OT.Error class from the docs.
    const error = new OTErrorClass(undefined, plainError.message);

    error.name = name;

    if (!plainError.stack) {
      try {
        throw plainError;
      } catch (e) {
        // IE populates the error's .stack when it is thrown, nothing to do here
      }
    }

    error.stack = plainError.stack;

    if (code) {
      error.code = code;
    }

    return error;
  };
};
