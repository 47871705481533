const globalOTHelpers = require('../../common-js-helpers/OTHelpers.js');
const globalWidget = require('./behaviour/widget.js');
const defineProperties = require('../../helpers/defineProperties.js');

module.exports = function MuteButtonFactory(deps = {}) {
  const OTHelpers = deps.OTHelpers || globalOTHelpers;
  const Widget = deps.Widget || globalWidget;

  return function MuteButton(options) {
    let muted = options.muted || false;

    const updateClasses = () => {
      if (muted) {
        OTHelpers.addClass(this.domElement, 'OT_active');
      } else {
        OTHelpers.removeClass(this.domElement, 'OT_active ');
      }
    };

    const onClick = () => {
      muted = !muted;

      updateClasses();

      if (muted) {
        this.parent.trigger('muted', this);
      } else {
        this.parent.trigger('unmuted', this);
      }

      return false;
    };


    // Private Event Callbacks
    const attachEvents = (elem) => {
      OTHelpers.on(elem, 'click', onClick);
    };

    const detachEvents = (elem) => {
      OTHelpers.off(elem, 'click', onClick);
    };

    defineProperties(this, {
      muted: {
        get() { return muted; },
        set(m) {
          muted = m;
          updateClasses();
        },
      },
    });

    // Mixin common widget behaviour
    const classNames = muted ? 'OT_edge-bar-item OT_mute OT_active' : 'OT_edge-bar-item OT_mute';
    Widget(this, {
      mode: options.mode,
      nodeName: 'button',
      htmlContent: 'Mute',
      htmlAttributes: {
        className: classNames,
        type: 'button',
      },
      onCreate: attachEvents,
      onDestroy: detachEvents,
    });

    let parentNode;
    this.remove = () => {
      if (this.domElement && this.domElement.parentNode) {
        parentNode = this.domElement.parentNode; // store in case we want to add it again
        this.domElement.parentNode.removeChild(this.domElement);
      }
    };

    this.add = () => {
      if (parentNode && this.domElement && !this.domElement.parentNode) {
        parentNode.appendChild(this.domElement);
      }
    };
  };
};
