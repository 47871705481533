let userAgent = 'Node';
if (typeof navigator !== 'undefined') {
  userAgent = navigator.userAgent;
}

module.exports = function isiOSFactory(deps = { userAgent }) {
  return /iPhone|iPad/.test(deps.userAgent)
    // This line is for detecting iPad on iOS 13+. See https://developer.apple.com/forums/thread/119186
    || (userAgent.includes('Mac') && 'ontouchend' in document);
};
